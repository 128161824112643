import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import OrdersList from './OrdersList'
import OrderCreate from './OrderCreate'
import OrderEdit from './OrderEdit'

export default {
  list: OrdersList,
  create: OrderCreate,
  edit: OrderEdit,
  icon: InsertDriveFileIcon
}