import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  ShowButton,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanField,
  BooleanInput,
  ReferenceField,
  GET_LIST,
  SelectInput
} from 'react-admin';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import TranslatedTextField from "~/components/TranslatedTextField";
import dataProvider from '~/providers/dataProvider'


export const FrameIcon = InsertDriveFileIcon;

const useStyles = makeStyles({
  rowCell: {
    whiteSpace: 'nowrap'
  },
});

export const FramesList = (props) => {
  const classes = useStyles()
  // const { basePath } = useListContext();

  return (<List {...props} >
    <Datagrid classes={classes}>
      <EditButton basePath="/frames" />
      <ShowButton />
      <ReferenceField label="resources.frames.fields.manufacturer" resource="frames" source="manufacturer" reference="manufacturers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <BooleanField source="visible" />
    </Datagrid>
  </List>)
};

const FrameTitle = ({ record }) => {
  return <span>Frame {record ? `"${record.employeeName}"` : ''}</span>;
};

export const FrameShow = (props) => {
  return (
    <Show title={<FrameTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <BooleanField source="visible" />
      </SimpleShowLayout>
    </Show>
  )
}

export const FrameEdit = (props) => {
  return (
    <Edit title={<FrameTitle />} {...props}>
      <SimpleForm aria-rowcount={4}>
        <TextInput source="name" />
        <BooleanInput source="visible" />
      </SimpleForm>
    </Edit>
  )
};

export const FrameCreate = (props) => {
  const [manufacturers, setManufacturers] = React.useState([])

  React.useEffect(() => {
    // dataProvider.getMany('users-permissions/manufacturers', {}).then((result) => {
    //   console.log(result)
    // })
    dataProvider(GET_LIST, 'manufacturers', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    }).then(({ data }) => {
      setManufacturers(data || [])
    })
  }, [])
  return (
    <Create title="Create a Frame" {...props}>
      <SimpleForm>
        <TextInput source="name" required />
        <SelectInput required source="manufacturer" defaultValue="" choices={[
          { id: "", name: "Not specified" },
          ...manufacturers
        ]} />
        <BooleanInput source="visible" defaultChecked />
      </SimpleForm>
    </Create>
  )
};

export default {
  icon: FrameIcon,
  list: FramesList
}