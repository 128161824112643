import * as React from "react";
import {
  ShowButton,
  EditButton,
  Filter,
  SelectInput,
  List,
  DateField,
  TextField,
  EmailField,
  TextInput,
  SearchInput,
} from 'react-admin';
// import { Link } from 'react-router-dom';
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { makeStyles } from '@material-ui/core';
import TranslatedTextField from '~/components/TranslatedTextField'
import { statusesChoises } from "~/constants";

const OrderFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SelectInput source="orderStatus" choices={statusesChoises} />
    <TextInput source="orderAddress" />
    <TextInput source="orderClientName" />
    <TextInput source="orderOfferNumber" />
    <TextInput source="orderClientEMail" />
    <TextInput source="orderClientMobilePhone" />
    <TextInput source="orderComment" />
  </Filter>
);

const useStyles = makeStyles({
  rowCell: {
    whiteSpace: 'nowrap'
  },
});

export const OrdersList = (props) => {
  const classes = useStyles()

  return (<List {...props} filters={<OrderFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
    {/* <div style={{ width: 'calc(100vw - 70px)', overflowX: 'auto' }}> */}
    <CustomizableDatagrid rowClick="edit" defaultColumns={[
      'orderOfferNumber',
      'orderStatus',
      'orderAddress',
      'orderClientName',
      'createdAt',
    ]} classes={classes}>
      <EditButton basePath="/orders" />
      {/* <ShowButton /> */}
      {/* <LinkToRelatedProducts /> */}
      <TextField source="orderOfferNumber" />
      <TranslatedTextField source="orderStatus" />
      <TextField source="orderAddress" />
      <EmailField source="orderClientEMail" />
      <TextField source="orderClientName" />
      <TextField source="orderClientMobilePhone" />
      <TextField sortable={false} source="orderComment" />
      <DateField source="createdAt" />
      <DateField source="modifiedAt" />
      <TextField source="orderCreatorName" />
    </CustomizableDatagrid>
    {/* </div> */}
  </List>)
};

export default OrdersList