import * as React from "react";
import {
  useListContext,
  List,
  TextField,
  TopToolbar,
  DateField,
  SortButton,
  ReferenceField,
  withTranslate,
  Filter,
  SelectInput,
  useTranslate
} from "react-admin";
import {
  Card,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import Image from "material-ui-image";
import ImageIcon from "@material-ui/icons/Image";
import TranslatedTextField from "~/components/TranslatedTextField";
export const PictureIcon = ImageIcon;

const cardStyle = {
  width: 300,
  minHeight: 300,
  margin: "0.5em",
  display: "inline-block",
  verticalAlign: "top",
};
const PicturesGrid = () => {
  const { ids, data, basePath } = useListContext();
  return (
    <div style={{ margin: "1em" }}>
      {ids.map((id) => (
        <Card key={id} style={cardStyle}>
          <CardHeader
            title={
              <TranslatedTextField record={data[id]} source="pictureType" />
            }
            subheader={<DateField record={data[id]} source="createdAt" />}
          // avatar={<Avatar icon={<PictureIcon />} />}
          />
          <CardContent>
            <Image src={data[id].pictureUrl} />
            {/* <TextField record={data[id]} source="body" /> */}
          </CardContent>
          <CardContent>
            {data[id].pictureOrderId &&
              <ReferenceField
                label="Order"
                reference="orders"
                resource="pictures"
                source="pictureOrderId"
                record={data[id]}
                basePath={basePath}
              >
                <TextField defaultValue="order" source="orderOfferNumber" />
              </ReferenceField>}
            {data[id].pictureItemId &&
              <ReferenceField
                label="Item"
                reference="items"
                resource="pictures"
                source="pictureItemId"
                record={data[id]}
                basePath={basePath}
                // link={(record, reference) => `/items/?itemId=${record.pictureItemId}`}
                link="show"
              >
                <TextField source="itemType" />
              </ReferenceField>}
          </CardContent>
          {/* <CardContent>
            about&nbsp;
                    <ReferenceField label="Post" resource="comments" record={data[id]} source="post_id" reference="pictures" basePath={basePath}>
              <TextField source="title" />
            </ReferenceField>
          </CardContent> */}
          {/* <CardActions style={{ textAlign: "right" }}>
            <EditButton
              resource="pictures"
              basePath={basePath}
              record={data[id]}
            />
            <ShowButton />
          </CardActions> */}
        </Card>
      ))}
    </div>
  );
};

const ListActions = () => (
  <TopToolbar>
    <SortButton label="resources.fields.sortBy" fields={["createdAt"]} />
    {/* <CreateButton basePath="/products" /> */}
    {/* <ExportButton /> */}
    {/* <ListFilter /> */}
  </TopToolbar>
);

const ListFilter = withTranslate((props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    {/* <TextInput label="Status" source="title" defaultValue="Hello, World!" /> */}
    <SelectInput source="pictureType" choices={[
      { id: "COMMON_SCHEME", name: props.translate("COMMON_SCHEME") },
      { id: "SILL_SCHEME", name: props.translate("SILL_SCHEME") },
      { id: "STOOL_SCHEME", name: props.translate("STOOL_SCHEME") },
      { id: "INSTALLATION_PHOTO", name: props.translate("INSTALLATION_PHOTO") },
      { id: "MEASUREMENT_PHOTO", name: props.translate("MEASUREMENT_PHOTO") },
      { id: "ROLLERSHUTTERS_SCHEME", name: props.translate("ROLLERSHUTTERS_SCHEME") },
    ]} />
    {/* <SelectInput source="itemSize" choices={[
      { id: 'Accurate', name: props.translate('Accurate') },
      { id: 'For offer', name: props.translate('For offer') },
    ]} />
    <SelectInput source="itemFrameColorInsideType" choices={[
      { id: 'Mass', name: props.translate('Mass') },
      { id: 'Membrane', name: props.translate('Membrane') },
    ]} />
    <SelectInput source="itemFrameColorOutsideType" choices={[
      { id: 'Mass', name: props.translate('Mass') },
      { id: 'Membrane', name: props.translate('Membrane') },
    ]} />
    <SelectInput source="itemSashColorInsideType" choices={[
      { id: 'Mass', name: props.translate('Mass') },
      { id: 'Membrane', name: props.translate('Membrane') },
    ]} />
    <SelectInput source="itemSashColorOutsideType" choices={[
      { id: 'Mass', name: props.translate('Mass') },
      { id: 'Membrane', name: props.translate('Membrane') },
    ]} />
    <BooleanInput source="itemSillExists" />
    <BooleanInput source="itemStoolExists" />
    <SelectInput source="itemRollerShuttersInstallationType" choices={[
      { id: 'New installation', name: t('New installation') },
      { id: 'Uninstallation', name: t('Uninstallation') },
      { id: 'Un- and Reinstallation', name: t('Un- and Reinstallation') },
    ]} /> */}
  </Filter>
));

const PostFilter = (props) => {
  
  const translate = useTranslate()
  return (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    {/* <TextInput label="Title" source="title" defaultValue="Hello, World!" /> */}
    <SelectInput alwaysOn source="pictureType" choices={[
      { id: "COMMON_SCHEME", name: translate("COMMON_SCHEME") },
      { id: "SILL_SCHEME", name: translate("SILL_SCHEME") },
      { id: "STOOL_SCHEME", name: translate("STOOL_SCHEME") },
      { id: "INSTALLATION_PHOTO", name: translate("INSTALLATION_PHOTO") },
      { id: "MEASUREMENT_PHOTO", name: translate("MEASUREMENT_PHOTO") },
    ]} />
  </Filter>
)};

export const PicturesList = (props) => (
  <List
    {...props}
    actions={<ListActions />}
    title="All pictures"
    filters={<PostFilter />}
    >
    <PicturesGrid />
  </List>
);

export default {
  icon: PictureIcon,
  list: PicturesList
}