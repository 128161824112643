import * as React from "react";
import { Admin, Resource } from 'react-admin';
// import simpleRestProvider from 'ra-strapi-rest';
import authProvider from '~/providers/authProvider'
import i18nProvider from '~/providers/i18nProvider'
import dataProvider from '~/providers/dataProvider'
// import Cookies from '~/helpers/Cookies';
import orders from '~/resources/orders';
import {
  UsersList,
  UserEdit,
  UserCreate,
  UserShow,
  UserIcon,
} from '~/resources/users';
import {
  ItemsList,
  ItemShow,
} from '~/resources/items'
import {
  GlassesList,
  GlassShow,
  GlassCreate,
  GlassIcon,
  GlassEdit,
} from '~/resources/glasses'
import {
  LockingsList,
  LockingShow,
  LockingCreate,
  LockingIcon,
  LockingEdit,
} from '~/resources/lockings'
import {
  ManufacturersList,
  ManufacturerShow,
  ManufacturerCreate,
  ManufacturerIcon,
  ManufacturerEdit,
} from '~/resources/manufacturers'
import {
  SillsList,
  SillShow,
  SillCreate,
  SillIcon,
  SillEdit,
} from '~/resources/sills'
import {
  StoolsList,
  StoolShow,
  StoolCreate,
  StoolIcon,
  StoolEdit,
} from '~/resources/stools'
import {
  RoomsList,
  RoomShow,
  RoomCreate,
  RoomIcon,
  RoomEdit,
} from '~/resources/rooms'
import {
  FramesList,
  FrameShow,
  FrameCreate,
  FrameIcon,
  FrameEdit,
} from '~/resources/frames'
import { PicturesList, PictureIcon } from '~/resources/pictures'
import { Layout } from './layout'
import logs from '~/resources/logs'

const initialState = {
  admin: {
    ui: {
      // automaticRefreshEnabled: true,
      sidebarOpen: true,
      // optimistic: true,
      // viewVersion: 0
    }
  }
}
const App = () => (
  <Admin layout={Layout} initialState={initialState} i18nProvider={i18nProvider} authProvider={authProvider} dataProvider={dataProvider}>
    <Resource name="orders" {...orders} />
    <Resource name="items" show={ItemShow} list={ItemsList} />
    <Resource name="users" show={UserShow} list={UsersList} edit={UserEdit} create={UserCreate} icon={UserIcon} />
    <Resource name="pictures"
      // show={UserShow}
      list={PicturesList}
      // edit={UserEdit}
      // create={UserCreate}
      icon={PictureIcon}
    />
    <Resource name="glasses" show={GlassShow} list={GlassesList} edit={GlassEdit} create={GlassCreate} icon={GlassIcon} />
    <Resource name="lockings" show={LockingShow} list={LockingsList} edit={LockingEdit} create={LockingCreate} icon={LockingIcon} />
    <Resource name="manufacturers" show={ManufacturerShow} list={ManufacturersList} edit={ManufacturerEdit} create={ManufacturerCreate} icon={ManufacturerIcon} />
    <Resource name="sills" show={SillShow} list={SillsList} edit={SillEdit} create={SillCreate} icon={SillIcon} />
    <Resource name="stools" show={StoolShow} list={StoolsList} edit={StoolEdit} create={StoolCreate} icon={StoolIcon} />
    <Resource name="rooms" show={RoomShow} list={RoomsList} edit={RoomEdit} create={RoomCreate} icon={RoomIcon} />
    <Resource name="frames" show={FrameShow} list={FramesList} edit={FrameEdit} create={FrameCreate} icon={FrameIcon} />
    <Resource {...logs} />
  </Admin>
);

export default App