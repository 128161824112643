import * as React from 'react';
import { AppBar, useLocale, useSetLocale } from 'react-admin';
import { Box, MenuItem, Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select';

const MyAppBar = props => {
  const setLocale = useSetLocale();
  const locale = useLocale();

  const handleChangeLocale = React.useCallback(({ target: { value: newLocale } }) => {
    localStorage.setItem('locale', newLocale)
    setLocale(newLocale)
  }, [setLocale])

  return (
    <AppBar {...props}>
      <Box flex="1">
        <Typography variant="h6" id="react-admin-title"></Typography>
      </Box>
      <Select
        value={locale}
        onChange={handleChangeLocale}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ru">Русский</MenuItem>
        <MenuItem value="nl">Dutch</MenuItem>
      </Select>
    </AppBar>
  )
};

export default MyAppBar;