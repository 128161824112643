export const en = {
  orders: {
    name: 'Orders',
    fields: {
      orderId: 'Order ID',
      orderStatus: 'Status',
      orderOfferNumber: 'Offer Number',
      orderAddress: 'Address',
      orderClientEMail: 'Client EMail',
      orderClientName: 'Client Name',
      orderClientMobilePhone: 'Client Phone',
      orderComment: 'Comment',
      orderModifiedDate: 'Modified Date',
      orderCreatedDate: 'Created Date',
      orderCreatorName: 'Creator Name',
    }
  },
  items: {
    name: 'Items',
    fields: {
      itemType: 'Type',
      itemRoomName: 'RoomName',
      itemAanslag: 'Aanslag',
      itemFrame: 'Frame',
      itemFrameManufacturer: 'Frame Manufacturer',
      itemFrameColorInside: 'Frame Color Inside',
      itemFrameColorInsideType: 'Frame Color Inside Type',
      itemFrameColorOutside: 'Frame Color Outside',
      itemFrameColorOutsideType: 'Frame Color Outside Type',
      itemSashColorInside: 'Sash Color Inside',
      itemSashColorInsideType: 'Sash Color Inside Type',
      itemSashColorOutside: 'Sash Color Outside',
      itemSashColorOutsideType: 'Sash Color Outside Type',
      itemOpening: 'Opening',
      itemView: 'View',
      itemGlass: 'Glass',
      itemSafetyGlass: 'Safety Glass',
      itemOtherGlass: 'Other Glass',
      itemLocking: 'Locking',
      itemAanslagBottomExists: 'Aanslag Bottom',
      itemAanslagTopExists: 'Aanslag Top',
      itemAanslagRightExists: 'Aanslag Right',
      itemAanslagLeftExists: 'Aanslag Left',
      itemSill: 'Sill',
      itemStool: 'Stool',
      itemGridExists: 'Grid',
      itemGrid: 'Grid',
      itemGridType: 'Grid Type',
      itemSafetyGlassExists: 'Safety Glass',
      itemSafetyGlassType: 'Safety Glass Type',
      itemRollerShuttersInstallationType: 'Roller Shutters',
      itemRollerShuttersControlType: 'Roller Shutters Control Type',
      itemRollerShuttersControlPosition: 'RollerShutters Control Position',
      itemRollerShuttersGuideRails: 'RollerShutters GuideRails',
      itemRollerShuttersGuideRailsColor: 'RollerShutters GuideRails Color',
      itemRollerShuttersPanelBoxColor: 'RollerShutters PanelBox Color',
      itemRollerShuttersSlatsColor: 'RollerShutters Slats Color',
      itemRollerShuttersBaseSlatColor: 'RollerShutters Base Slat Color',
      itemRollerShuttersComments: 'RollerShutters Comments',
      itemSize: 'Size',
      itemSizeAanslag: 'Aanslag in Size',
      itemLocation: 'Location',
      itemComment: 'Comment',
      itemCreatorName: 'CreatorName',
      itemFinishing: 'Finishing',
      itemWeldJoint: 'Weld joint',
    }
  },
  pictures: {
    name: 'Pictures',
    fields: {
      createdAt: 'Created At'
    }
  },
  users: {
    name: 'Users',
    fields: {
      username: 'username',
      email: 'email',
      userRole: 'userRole',
      provider: 'provider',
      password: 'password',
      confirmed: 'confirmed',
      blocked: 'blocked',
      employeeName: 'employeeName',
      employeeGender: 'employeeGender',
    }
  },
  manufacturers: {
    name: 'Manufacturers',
    fields: {
      name: 'Name',
      visible: 'Visible',
      manufacturer: 'Manufacturer'
    }
  },
  sills: {
    name: 'Sills',
    fields: {
      name: 'Name',
      visible: 'Visible'
    }
  },
  stools: {
    name: 'Stools',
    fields: {
      name: 'Name',
      visible: 'Visible'
    }
  },
  frames: {
    name: 'Frames',
    fields: {
      name: 'Name',
      visible: 'Visible'
    }
  },
  glasses: {
    name: 'Glasses',
    fields: {
      name: 'Name',
      visible: 'Visible'
    }
  },
  rooms: {
    name: 'Rools',
    fields: {
      name: 'Name',
      visible: 'Visible'
    }
  },
  fields: {
    sortBy: 'Sort by'
  },
  logs: {
    name: 'Logs',
    fields: {
      actionType: 'Action Type',
      documentType: 'Document Type',
      documentField: 'Document Field',
      actionDate: 'Action Date',
      prevValue: 'Prev Value',
      nextValue: 'Next Value',
      user: 'User',
    }
  }
};

export const ru = {
  orders: {
    name: 'Заказы',
    fields: {
      orderId: 'Order ID',
      orderStatus: 'Статус',
      orderOfferNumber: 'Номер оферты',
      orderAddress: 'Адрес',
      orderClientEMail: 'Эл.почта клиента',
      orderClientName: 'Имя клиента',
      orderClientMobilePhone: 'Телефон клиента',
      orderComment: 'Комментарий',
      orderModifiedDate: 'Дата изменения',
      orderCreatedDate: 'Дата создания',
      orderCreatorName: 'Автор',
    }
  },
  items: {
    name: 'Items',
    fields: {
      itemType: 'Тип',
      itemRoomName: 'Комната',
      itemAanslag: 'Aanslag',
      itemFrame: 'Профиль',
      itemFrameManufacturer: 'Изготовитель профиля',
      itemFrameColorInside: 'Цвет профиля изнутри',
      itemFrameColorInsideType: 'Тип окраски профиля изнутри',
      itemFrameColorOutside: 'Цвет профиля снаружи',
      itemFrameColorOutsideType: 'Тип окраски профиля снаружи',
      itemSashColorInside: 'Цвет рамы изнутри',
      itemSashColorInsideType: 'Тип окраски рамы изнутри',
      itemSashColorOutside: 'Цвет рамы снаружи',
      itemSashColorOutsideType: 'Тип окраски рамы снаружи',
      itemOpening: 'Открытие',
      itemView: 'Вид',
      itemGlass: 'Стекло',
      itemSafetyGlass: 'Защитное стекло',
      itemOtherGlass: 'Другое стекло',
      itemLocking: 'Блокировка',
      itemAanslagBottomExists: 'Aanslag внизу',
      itemAanslagTopExists: 'Aanslag вверху',
      itemAanslagRightExists: 'Aanslag справа',
      itemAanslagLeftExists: 'Aanslag слева',
      itemSill: 'Отлив',
      itemStool: 'Подоконник',
      itemGridExists: 'Сетка',
      itemGrid: 'Сетка',
      itemGridType: 'Тип сетки',
      itemSafetyGlassExists: 'Защитное стекло',
      itemSafetyGlassType: 'Тип защитного стекла',
      itemRollerShuttersInstallationType: 'Рольставни',
      itemRollerShuttersControlType: 'Тип управления рольставнями',
      itemRollerShuttersControlPosition: 'Расположение управления рольставнями',
      itemRollerShuttersGuideRails: 'Направляющие рольставней',
      itemRollerShuttersGuideRailsColor: 'Цвет направляющих рольставней',
      itemRollerShuttersPanelBoxColor: 'Цвет короба рольставней',
      itemRollerShuttersSlatsColor: 'Цвет ламелей рольставней',
      itemRollerShuttersBaseSlatColor: 'Цвет нижней ламели рольставней',
      itemRollerShuttersComments: 'Комментарий к рольставням',
      itemSize: 'Размер',
      itemSizeAanslag: 'Aanslag в размерах',
      itemLocation: 'Расположение',
      itemComment: 'Комментарий',
      itemCreatorName: 'Автор',
      itemFinishing: 'Отделка',
      itemWeldJoint: 'Сварное соединение',
    }
  },
  pictures: {
    name: 'Изображения',
    fields: {
      createdAt: 'Созданы'
    }
  },
  users: {
    name: 'Пользователи',
    fields: {
      username: 'Логин пользователя',
      email: 'Эл.почта',
      userRole: 'Роль',
      provider: 'Провайдер',
      password: 'Пароль',
      confirmed: 'Подтверждён',
      blocked: 'Заблокирован',
      employeeName: 'Имя сотрудника',
      employeeGender: 'Пол сотрудника',
    }
  },
  manufacturers: {
    name: 'Изготовители',
    fields: {
      name: 'Наименование',
      visible: 'Видимость'
    }
  },
  sills: {
    name: 'Отливы',
    fields: {
      name: 'Наименование',
      visible: 'Видимость'
    }
  },
  stools: {
    name: 'Подоконники',
    fields: {
      name: 'Наименование',
      visible: 'Видимость'
    }
  },
  frames: {
    name: 'Профили',
    fields: {
      name: 'Наименование',
      visible: 'Видимость',
      manufacturer: 'Производитель'
    }
  },
  glasses: {
    name: 'Стёкла',
    fields: {
      name: 'Наименование',
      visible: 'Видимость'
    }
  },
  rooms: {
    name: 'Комнаты',
    fields: {
      name: 'Наименование',
      visible: 'Видимость'
    }
  },
  fields: {
    sortBy: 'Сортировать по'
  },
  logs: {
    name: 'Логи',
    fields: {
      actionType: 'Тип действия',
      documentType: 'Тип документа',
      documentField: 'Поле документа',
      actionDate: 'Дата действия',
      prevValue: 'Пред. значение',
      nextValue: 'След. значение',
      user: 'Пользователь',
    }
  }
};

export const nl = {
  orders: {
    name: 'Bestellingen',
    fields: {
      orderId: 'Bestel ID',
      orderStatus: 'Toestand',
      orderOfferNumber: 'Offer Number',
      orderAddress: 'Adres',
      orderClientEMail: 'E-mail van de klant',
      orderClientName: 'Klantnaam',
      orderClientMobilePhone: 'Klant telefoon',
      orderComment: 'Commentaar',
      orderModifiedDate: 'Bewerkt',
      orderCreatedDate: 'Gemaakt',
      orderCreatorName: 'Naam maker',
    }
  },
  items: {
    name: 'Metingen',
    fields: {
      itemType: 'Type',
      itemRoomName: 'Kamer naam',
      itemAanslag: 'Aanslag',
      itemFrame: 'Profiel',
      itemFrameManufacturer: 'Profiel Fabrikant',
      itemFrameColorInside: 'Profiel Kleur Binnen',
      itemFrameColorInsideType: 'Profiel Kleur Binnen Type',
      itemFrameColorOutside: 'Profiel Kleur Buiten',
      itemFrameColorOutsideType: 'Profiel Kleur Buiten Type',
      itemSashColorInside: 'Vleugel Kleur Binnen',
      itemSashColorInsideType: 'Vleugel Kleur Binnen Type',
      itemSashColorOutside: 'Vleugel Kleur Buiten',
      itemSashColorOutsideType: 'Vleugel Kleur Buiten Type',
      itemOpening: 'Draaien',
      itemView: 'Uitzicht',
      itemGlass: 'Glas',
      itemSafetyGlass: 'Veiligheidsglas',
      itemOtherGlass: 'Ander Glas',
      itemLocking: 'Sluiting',
      itemAanslagBottomExists: 'Aanslag Onder',
      itemAanslagTopExists: 'Aanslag Boven',
      itemAanslagRightExists: 'Aanslag Rechts',
      itemAanslagLeftExists: 'Aanslag Links',
      itemSill: 'Dorpel',
      itemStool: 'Vensterbank',
      itemGridExists: 'Hor',
      itemGrid: 'Hor',
      itemGridType: 'Hor Type',
      itemRollerShuttersInstallationType: 'Rolluik',
      itemRollerShuttersControlType: 'Bediening',
      itemRollerShuttersControlPosition: 'Welke kant bediening',
      itemRollerShuttersGuideRails: 'Geleiders',
      itemRollerShuttersGuideRailsColor: 'Kleur geleiders',
      itemRollerShuttersPanelBoxColor: 'Kleur omkasting',
      itemRollerShuttersSlatsColor: 'Kleur lamellen',
      itemRollerShuttersBaseSlatColor: 'Kleur onder lamel',
      itemRollerShuttersComments: 'Opmerkingen',
      itemSize: 'Maten',
      itemSizeAanslag: 'Aanslag Maten',
      itemLocation: 'Location',
      itemComment: 'Commentaar',
      itemCreatorName: 'CreatorName',
      itemFinishing: 'Afwerking',
      itemWeldJoint: 'Lasverbinding',
    }
  },
  pictures: {
    name: 'Afbeeldingen',
    fields: {
      createdAt: 'Gemaakt bij'
    }
  },
  users: {
    name: 'Gebruikers',
    fields: {
      username: 'username',
      email: 'email',
      userRole: 'userRole',
      provider: 'provider',
      password: 'password',
      confirmed: 'confirmed',
      blocked: 'blocked',
      employeeName: 'employeeName',
      employeeGender: 'employeeGender',
    }
  },
  manufacturers: {
    name: 'Fabrikanten',
    fields: {
      name: 'Naam',
      visible: 'Zichtbaar'
    }
  },
  sills: {
    name: 'Dorpelen',
    fields: {
      name: 'Naam',
      visible: 'Zichtbaar'
    }
  },
  stools: {
    name: 'Vensterbanken',
    fields: {
      name: 'Naam',
      visible: 'Zichtbaar'
    }
  },
  frames: {
    name: 'Profielen',
    fields: {
      name: 'Naam',
      visible: 'Zichtbaar',
      manufacturer: 'Fabrikant'
    }
  },
  glasses: {
    name: 'Glazen',
    fields: {
      name: 'Naam',
      visible: 'Zichtbaar'
    }
  },
  rooms: {
    name: 'Kamers',
    fields: {
      name: 'Naam',
      visible: 'Zichtbaar'
    }
  },
  fields: {
    sortBy: 'Sorteer op'
  },
  logs: {
    name: 'Logboeken',
    fields: {
      actionType: 'Actie type',
      documentType: 'Type document',
      documentField: 'Documentveld',
      actionDate: 'Actie Datum',
      prevValue: 'Prev Waarde',
      nextValue: 'Volgende waarde',
      user: 'Gebruiker',
    }
  }
};


export default {
  en, ru, nl
}