import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  ShowButton,
  Filter,
  SelectInput,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  TextInput,
  BooleanField,
  BooleanInput,
  PasswordInput,
  DateInput,
  GET_LIST
} from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import dataProvider from '~/providers/dataProvider'
import { userInfo } from "os";


export const UserIcon = PersonIcon;

const UserFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="role" disabled choices={[
      { id: "MEASURER", name: "Measurer" },
      { id: "INSTALLER", name: "Installer" },
    ]} />
  </Filter>
);

const useStyles = makeStyles({
  rowCell: {
    whiteSpace: 'nowrap'
  },
});

export const UsersList = withWidth()((props) => {
  const classes = useStyles()
  console.log(props)
  return (<List {...props} >
    {/* <div style={{ width: 'calc(100vw - 70px)', overflowX: 'auto' }}> */}
    <Datagrid classes={classes}>
      <EditButton basePath="/users" />
      <ShowButton />
      <TextField source="employeeName" />
      <TextField source="username" />
      <TextField source="email" />
      {/* <TextField source="provider" /> */}
      {/* <TextField source="password" /> */}
      <BooleanField source="confirmed" />
      <BooleanField source="blocked" />
      {/* <TextField source="role" /> */}
      <TextField source="employeeGender" />
      <DateField source="employeeDateOfBirth" />
    </Datagrid>
    {/* </div> */}
  </List>)
});

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.employeeName}"` : ''}</span>;
};

export const UserShow = (props) => {
  return (
    <Show title={<UserTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="username" />
        <TextField source="email" />
        {/* <TextField source="provider" /> */}
        <TextField source="password" />
        <TextField source="confirmed" />
        <TextField source="blocked" />
        {/* <TextField source="role" /> */}
        <TextField source="employeeName" />
        <TextField source="employeeGender" />
        <DateField source="employeeDateOfBirth" />
      </SimpleShowLayout>
    </Show>
  )
}

export const UserEdit = (props) => {
  return (
    <Edit title={<UserTitle />} {...props}>
      <SimpleForm aria-rowcount={4}>
        <TextInput source="username" />
        <TextInput source="email" />
        {/* <TextInput source="provider" /> */}
        <PasswordInput source="password" />
        <BooleanInput source="confirmed" />
        <BooleanInput source="blocked" />
        {/* <TextInput source="role" /> */}
        <TextInput source="employeeName" />
        {/* <TextInput source="employeeGender" /> */}
        <SelectInput source="employeeGender" disabled choices={[
          { id: "male", name: "Male" },
          { id: "female", name: "Female" },
        ]} />
        <DateField source="employeeDateOfBirth" />
      </SimpleForm>
    </Edit>
  )
};

export const UserCreate = (props) => {
  const [roles, setRoles] = React.useState([])

  React.useEffect(() => {
    // dataProvider.getMany('users-permissions/roles', {}).then((result) => {
    //   console.log(result)
    // })
    dataProvider(GET_LIST, 'users-permissions/roles', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    }).then(({ data }) => {
      const roles = (data.roles || [])
        .map(({ name, id }) => ({ id, name }))
        .filter(({ name }) => !['Public', 'Authenticated'].includes(name))
      setRoles(roles)
    })
  }, [])

  return (
    <Create title="Create a User" {...props}>
      <SimpleForm>
        <TextInput source="username" />
        <TextInput source="email" />
        <SelectInput source="userRole" defaultValue="" choices={[
          { id: "", name: "Not specified" },
          ...roles
        ]} />
        <TextInput source="provider" defaultValue="local" disabled />
        <TextInput source="password" />
        <BooleanInput source="confirmed" defaultChecked />
        <BooleanInput source="blocked" defaultChecked={false} />
        <TextInput source="employeeName" />
        <SelectInput required source="employeeGender" disabled choices={[
          { id: "male", name: "Male" },
          { id: "female", name: "Female" },
        ]} />
        <DateInput source="employeeDateOfBirth" />
      </SimpleForm>
    </Create>
  )
};

export default {
  icon: UserIcon,
  list: UsersList
}