import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  ShowButton,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanField,
  BooleanInput,
} from 'react-admin';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from '@material-ui/core';
import { SillsList } from "./sills";


export const StoolIcon = InsertDriveFileIcon;

const useStyles = makeStyles({
  rowCell: {
    whiteSpace: 'nowrap'
  },
});

export const StoolsList = (props) => {
  const classes = useStyles()

  return (<List {...props} >
    <Datagrid classes={classes}>
      <EditButton basePath="/stools" />
      <ShowButton />
      <TextField source="name" />
      <BooleanField source="visible" />
    </Datagrid>
  </List>)
};

const StoolTitle = ({ record }) => {
  return <span>Stool {record ? `"${record.employeeName}"` : ''}</span>;
};

export const StoolShow = (props) => {
  return (
    <Show title={<StoolTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <BooleanField source="visible" />
      </SimpleShowLayout>
    </Show>
  )
}

export const StoolEdit = (props) => {
  return (
    <Edit title={<StoolTitle />} {...props}>
      <SimpleForm aria-rowcount={4}>
        <TextInput source="name" />
        <BooleanInput source="blocked" />
      </SimpleForm>
    </Edit>
  )
};

export const StoolCreate = (props) => {
  return (
    <Create title="Create a Stool" {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <BooleanInput source="visible" defaultChecked />
      </SimpleForm>
    </Create>
  )
};

export default {
  icon: StoolIcon,
  list: SillsList
}