import React, { useState, useEffect, FC } from "react";
// import { jsPDF } from "jspdf";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Typography from '@material-ui/core/Typography';
// import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Pictures from "~/components/Pictures";
import {
  TextField,
  ShowButton,
  FieldProps,
  useTranslate,
  // useListContext,
  useQueryWithStore,
  Loading,
  Error,
  ReferenceField,
  BooleanField,
} from "react-admin";

import { Order } from "../../types";
import TranslatedTextField from "~/components/TranslatedTextField";

// const useStyles = makeStyles({
//   rightAlignedCell: { textAlign: "right" },
// });

const Items: FC<FieldProps<Order>> = ({ record }) => {
  const translate = useTranslate();
  const { data = [], loading, error, loaded } = useQueryWithStore({
    type: "getList",
    resource: "items",
    payload: {
      filter: { itemOrderId: record.orderId },
      sort: { field: "itemType", order: "ASC" },
      pagination: { page: 1, perPage: 100 },
    },
  });

  // return null
  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  if (!loaded || !record) return null;

  return (
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>{translate("resources.items.fields.itemType")}</TableCell>
          <TableCell>
            {translate("resources.items.fields.itemRoomName")}
          </TableCell>
          <TableCell>{translate("resources.items.fields.itemSize")}</TableCell>
          <TableCell>
            {translate("resources.items.fields.itemSizeAanslag")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <Row key={item.id} item={item} />
        ))}
      </TableBody>
    </Table>
  );
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  collapsedCell: { padding: 0 },
  picturesRow: { height: 240 },
  hidden: { display: "none" },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function Row({ item }) {
  const [open, setOpen] = useState(false);
  const [aanslag, setAanslag] = useState([]);
  const classes = useRowStyles();
  const translate = useTranslate();

  useEffect(() => {
    const arr = [];
    item.itemAanslagBottomExists && arr.push(translate("Bottom"));
    item.itemAanslagTopExists && arr.push(translate("Top"));
    item.itemAanslagRightExists && arr.push(translate("Right"));
    item.itemAanslagLeftExists && arr.push(translate("Left"));
    setAanslag(arr);
  }, [
    item.itemAanslagBottomExists,
    item.itemAanslagLeftExists,
    item.itemAanslagRightExists,
    item.itemAanslagTopExists,
    translate,
  ]);

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <TranslatedTextField source="itemType" record={item} />
        </TableCell>
        <TableCell>{item.itemRoomName}</TableCell>
        <TableCell>
          <TranslatedTextField source="itemSize" record={item} />
          {/* {item.itemSize} */}
        </TableCell>
        <TableCell>
          <TranslatedTextField source="itemSizeAanslag" record={item} />
          {/* {item.itemSizeAanslag} */}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.collapsedCell} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TableBody>
                {!item.itemFrameManufacturer ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate(
                        "resources.items.fields.itemFrameManufacturer"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <ReferenceField
                        link={false}
                        label="Frame Manufacturer"
                        resource="items"
                        source="itemFrameManufacturer"
                        reference="manufacturers"
                        record={item}
                        basePath="/items"
                      >
                        <TextField
                          source="name"
                          record={item}
                          label="resources.items.fields.itemFrameManufacturer"
                        />
                      </ReferenceField>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {!item.itemFrame ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemFrame")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <ReferenceField
                        link={false}
                        label="Frame"
                        resource="items"
                        source="itemFrame"
                        reference="frames"
                        record={item}
                        basePath="/items"
                      >
                        <TextField
                          source="name"
                          record={item}
                          label="resources.items.fields.itemFrame"
                        />
                      </ReferenceField>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {!item.itemWeldJoint ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemWeldJoint")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {/* <TranslatedTextField
                      source="itemWeldJoint"
                      record={item}
                    /> */}
                      {item.itemWeldJoint}
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {aanslag.length === 0 ? null : (
                  <StyledTableRow>
                    <StyledTableCell>Aanslag</StyledTableCell>
                    <StyledTableCell>{aanslag.join(" | ")}</StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemOpening ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemOpening")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TranslatedTextField record={item} source="itemOpening" />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {!item.itemView ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemView")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TranslatedTextField record={item} source="itemView" />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {!item.itemLocking ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemLocking")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <ReferenceField
                        link={false}
                        label="Locking"
                        resource="items"
                        source="itemLocking"
                        reference="lockings"
                        record={item}
                        basePath="/items"
                      >
                        <TextField
                          source="name"
                          record={item}
                          label="resources.items.fields.itemLocking"
                        />
                      </ReferenceField>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {!item.itemGlass ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemGlass")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <ReferenceField
                        link={false}
                        label="Glass"
                        resource="items"
                        source="itemGlass"
                        reference="glasses"
                        record={item}
                        basePath="/items"
                      >
                        <TextField source="name" record={item} />
                      </ReferenceField>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {!item.itemOtherGlass ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemOtherGlass")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        source="itemOtherGlass"
                        record={item}
                        label="resources.items.fields.itemOtherGlass"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {!item.itemSafetyGlass ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemSafetyGlass")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TranslatedTextField
                        source="itemSafetyGlass"
                        record={item}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemFrameColorInside &&
                  !item.itemFrameColorInsideType ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemFrameColorInside")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField source="itemFrameColorInside" record={item} />
                      {" - "}
                      <TranslatedTextField
                        source="itemFrameColorInsideType"
                        record={item}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemFrameColorOutside &&
                  !item.itemFrameColorOutsideType ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate(
                        "resources.items.fields.itemFrameColorOutside"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField source="itemFrameColorOutside" record={item} />
                      {" - "}
                      <TranslatedTextField
                        source="itemFrameColorOutsideType"
                        record={item}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemSashColorInside &&
                  !item.itemSashColorInsideType ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemSashColorInside")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField source="itemSashColorInside" record={item} />
                      {" - "}
                      <TranslatedTextField
                        source="itemSashColorInsideType"
                        record={item}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemSashColorOutside &&
                  !item.itemSashColorOutsideType ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemSashColorOutside")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField source="itemSashColorOutside" record={item} />
                      {" - "}
                      <TranslatedTextField
                        source="itemSashColorOutsideType"
                        record={item}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {/* {!item.itemSize ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemSize")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TranslatedTextField record={item} source="itemSize" />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemSizeAanslag ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemSizeAanslag")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TranslatedTextField
                        record={item}
                        source="itemSizeAanslag"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )} */}

                {!item.itemRollerShuttersInstallationType ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate(
                        "resources.items.fields.itemRollerShuttersInstallationType"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TranslatedTextField
                        record={item}
                        source="itemRollerShuttersInstallationType"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemRollerShuttersControlType ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate(
                        "resources.items.fields.itemRollerShuttersControlType"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TranslatedTextField
                        record={item}
                        source="itemRollerShuttersControlType"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemRollerShuttersControlPosition ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate(
                        "resources.items.fields.itemRollerShuttersControlPosition"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TranslatedTextField
                        record={item}
                        source="itemRollerShuttersControlPosition"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemRollerShuttersGuideRails ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate(
                        "resources.items.fields.itemRollerShuttersGuideRails"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        record={item}
                        source="itemRollerShuttersGuideRails"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemRollerShuttersGuideRailsColor ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate(
                        "resources.items.fields.itemRollerShuttersGuideRailsColor"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        record={item}
                        source="itemRollerShuttersGuideRailsColor"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemRollerShuttersPanelBoxColor ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate(
                        "resources.items.fields.itemRollerShuttersPanelBoxColor"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        record={item}
                        source="itemRollerShuttersPanelBoxColor"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemRollerShuttersSlatsColor ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate(
                        "resources.items.fields.itemRollerShuttersSlatsColor"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        record={item}
                        source="itemRollerShuttersSlatsColor"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemRollerShuttersBaseSlatColor ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate(
                        "resources.items.fields.itemRollerShuttersBaseSlatColor"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        record={item}
                        source="itemRollerShuttersBaseSlatColor"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemRollerShuttersComments ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate(
                        "resources.items.fields.itemRollerShuttersComments"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        component="pre"
                        record={item}
                        source="itemRollerShuttersComments"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}



                {!item.itemGridExists ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemGridExists")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <BooleanField source="itemGridExists" record={item} />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {!item.itemGrid ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemGrid")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TranslatedTextField source="itemGrid" record={item} />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemFinishing ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemFinishing")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <BooleanField source="itemFinishing" record={item} />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemSill ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemSill")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <ReferenceField
                        link={false}
                        label="Sill"
                        resource="items"
                        source="itemSill"
                        reference="sills"
                        record={item}
                        basePath="/items"
                      >
                        <TextField
                          source="name"
                          record={item}
                          label="resources.items.fields.itemSill"
                        />
                      </ReferenceField>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {!item.itemStool ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemStool")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <ReferenceField
                        link={false}
                        label="Stool"
                        resource="items"
                        source="itemStool"
                        reference="stools"
                        record={item}
                        basePath="/items"
                      >
                        <TextField
                          source="name"
                          record={item}
                          label="resources.items.fields.itemStool"
                        />
                      </ReferenceField>
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemLocation ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemLocation")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        component="pre"
                        record={item}
                        source="itemLocation"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}

                {!item.itemComment ? null : (
                  <StyledTableRow>
                    <StyledTableCell>
                      {translate("resources.items.fields.itemComment")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        component="pre"
                        record={item}
                        source="itemComment"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {item.itemType !== 'COMMON' && <TableRow className={classes.picturesRow}>
                  <TableCell colSpan={5}>
                    <Pictures filter={{ pictureItemId: item.itemId }} />
                  </TableCell>
                </TableRow>}
                {/* <TableRow>
                  <TableCell colSpan={5}>
                    <ShowButton basePath="/items" record={item} />
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
/**
 *

  "itemLocation": "",
  "itemComment": "",
  "itemCreatorName": "Ivan Measurer",
  "itemCreatedDate": "2020-10-28T22:15:48.921Z",
  "itemModifiedDate": "2020-10-28T22:17:59.485Z",

  "itemRollerShuttersInstallationType": "",
  "itemRollerShuttersControlType": "",
  "itemRollerShuttersControlPosition": "",
  "itemRollerShuttersGuideRails": "",

  "itemRollerShuttersComments": "",
  "createdAt": "2020-10-28T22:24:11.933Z",
  "updatedAt": "2020-10-28T22:24:11.943Z",
  "__v": 0,
  "itemLocking": "5f95ce130e44ec6f049ba5ce",

 */

export default Items;
