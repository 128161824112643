import simpleRestProvider from 'ra-strapi-rest';
import Cookies from '~/helpers/Cookies';
import { fetchUtils } from 'react-admin';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = Cookies.getCookie('token')
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}
const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient);

export default dataProvider;