/* tslint:disable */
import * as React from "react";
import {
  SelectInput,
  Edit,
  TextInput,
  Toolbar,
  FormWithRedirect,
  useDataProvider,
  useTranslate,
  TopToolbar,
  useNotify,
} from 'react-admin';
import { jsPDF, ImageOptions } from "jspdf";
import autoTable from 'jspdf-autotable';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Spacer from '../../components/Spacer'
import Items from './Items'
import Pictures from '~/components/Pictures'
// import Logs from "~/components/Logs";
import { stringify } from 'query-string';
import { statusesChoises } from '~/constants'

const useEditStyles = makeStyles({
  root: { alignItems: 'flex-start' },
});

const OrderTitle = ({ record }: any) => {
  return <span>Order {record ? `"${record.orderOfferNumber}"` : ''}</span>;
};

const CustomerDetails = ({ }: any) => (
  <Box display="flex" flexDirection="column">
    <TextInput fullWidth source="orderClientName" label="resources.orders.fields.orderClientName" />
    <TextInput fullWidth source="orderClientMobilePhone" label="resources.orders.fields.orderClientMobilePhone" />
    <TextInput fullWidth source="orderClientEMail" label="resources.orders.fields.orderClientEMail" />
  </Box>
);

const LinkToRelatedLogs = (({ record }: any) => {
  return record ? (
    <Button
      // startIcon={<EyeIcon />}
      color="primary"
      component={Link}
      to={{
        pathname: '/logs',
        search: stringify({
          page: 1,
          perPage: 50,
          sort: 'createdAt',
          order: 'ASC',
          filter: JSON.stringify({ documentId: record.id, documentType: 'ORDER' }),
        }),
      }}
    >
      Logs
      {/* {translate('Logs')} */}
    </Button>
  ) : null;
});

const OrderForm = (props: any) => {
  const translate = useTranslate();
  return (<FormWithRedirect
    {...props}
    render={(formProps: any) => (
      <Box>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={8}>
                <Typography variant="h6" gutterBottom>
                  {translate(
                    'Order'
                  )}
                </Typography>
                <LinkToRelatedLogs />
                <Grid container direction="column">
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} sm={12} md={6}>
                      <SelectInput
                        fullWidth label="resources.orders.fields.orderStatus"
                        source="orderStatus"
                        choices={statusesChoises} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextInput fullWidth source="orderOfferNumber" label="resources.orders.fields.orderOfferNumber" />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextInput fullWidth multiline source="orderComment" label="resources.orders.fields.orderComment" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextInput fullWidth multiline source="orderAddress" label="resources.orders.fields.orderAddress" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="h6" gutterBottom>
                  {translate(
                    'Client'
                  )}
                </Typography>

                <Grid item xs={12} sm={12} md={12}>
                  <CustomerDetails record={formProps.record} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Pictures filter={{ pictureOrderId: formProps.record.orderId }} />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12}>
              <TextInput fullWidth source="orderAddress" label="resources.orders.fields.orderAddress" />
            </Grid> */}
            <Typography variant="h6" gutterBottom>
              {translate('resources.items.name')}
            </Typography>
            <Box>
              <Items record={formProps.record} />
            </Box>
            <Spacer />
          </CardContent>
          <Toolbar
            record={formProps.order}
            basePath={formProps.basePath}
            undoable={true}
            invalid={formProps.invalid}
            handleSubmit={formProps.handleSubmit}
            saving={formProps.saving}
            resource="orders"
          // alwaysEnableSaveButton={false}
          />
        </Card>
        {/* <Logs filter={{ documentType: 'ORDER', documentId: props.record.id }} /> */}
      </Box>)
    } />)
}

const loadImage = (imgSrc: string): Promise<HTMLImageElement> => {
  const image = document.createElement('img')
  image.src = imgSrc
  image.setAttribute('crossorigin', 'anonymous');
  return new Promise((resolve, reject) => {
    image.onload = () => resolve(image)
    image.onabort = (error) => { console.log(error); reject(error) }
  })
}

const resizeImage = async (imgSrc, resizingFactor = 0.5) => {
  try {
    const image = await loadImage(imgSrc)

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const originalWidth = image.width;
    const originalHeight = image.height;

    const canvasWidth = originalWidth * resizingFactor;
    const canvasHeight = originalHeight * resizingFactor;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    context.drawImage(
      image,
      0,
      0,
      originalWidth * resizingFactor,
      originalHeight * resizingFactor
    );
    return canvas.toDataURL();
  } catch (error) {
    console.log(error)
    return null
  }
}

const Actions = ({ data: order = {} }: any) => {
  const [disabled, setDisabled] = React.useState(false)
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const notify = useNotify();

  const generateDocuments = React.useCallback(
    () => {
      setDisabled(true);

      (async () => {
        try {
          const { data: items }: { data: any[] } = await dataProvider.getList('items', {
            filter: { itemOrderId: order.orderId },
            sort: { field: 'itemType', order: 'ASC' },
            pagination: { page: 1, perPage: 100 },
          });

          const [
            { data: frames },
            { data: manufacturers },
            { data: lockings },
            { data: glasses },
            { data: sills },
            { data: stools },
          ] = await
              Promise.all([
                dataProvider.getMany('frames', { ids: items.reduce((prev, { itemFrame }) => itemFrame ? [itemFrame, ...prev] : prev, []) }),
                dataProvider.getMany('manufacturers', { ids: items.reduce((prev, { itemFrameManufacturer }) => itemFrameManufacturer ? [itemFrameManufacturer, ...prev] : prev, []) }),
                dataProvider.getMany('lockings', { ids: items.reduce((prev, { itemLocking }) => itemLocking ? [itemLocking, ...prev] : prev, []) }),
                dataProvider.getMany('glasses', { ids: items.reduce((prev, { itemGlass }) => itemGlass ? [itemGlass, ...prev] : prev, []) }),
                dataProvider.getMany('sills', { ids: items.reduce((prev, { itemSill }) => itemSill ? [itemSill, ...prev] : prev, []) }),
                dataProvider.getMany('stools', { ids: items.reduce((prev, { itemStool }) => itemStool ? [itemStool, ...prev] : prev, []) })
              ])

          const framesById = frames.reduce((prev, { id, name }) => ({ [id]: name, ...prev }), {})
          const manufacturersById = manufacturers.reduce((prev, { id, name }) => ({ [id]: name, ...prev }), {})
          const lockingsById = lockings.reduce((prev, { id, name }) => ({ [id]: name, ...prev }), {})
          const glassesById = glasses.reduce((prev, { id, name }) => ({ [id]: name, ...prev }), {})
          const sillsById = sills.reduce((prev, { id, name }) => ({ [id]: name, ...prev }), {})
          const stoolsById = stools.reduce((prev, { id, name }) => ({ [id]: name, ...prev }), {})

          const doc = new jsPDF();
          doc.addFont("PTSans-Regular.ttf", "PTSans", "normal");
          doc.setFont("PTSans"); // set font
          doc.setFontSize(10)

          for (let index = 0; index < items.length; index++) {
            const item = items[index];
            const { data: [scheme] } = await dataProvider.getList('pictures', {
              filter: { pictureItemId: item.itemId, pictureType: 'COMMON_SCHEME' },
              sort: { field: 'pictureType', order: 'ASC' },
              pagination: { page: 1, perPage: 100 },
            });

            const commonTableBody = []
            const aanslag = []

            item.itemAanslagBottomExists && aanslag.push(translate("Bottom"));
            item.itemAanslagTopExists && aanslag.push(translate("Top"));
            item.itemAanslagRightExists && aanslag.push(translate("Right"));
            item.itemAanslagLeftExists && aanslag.push(translate("Left"));

            if (index === 0) {
              doc.text(translate('Order') + ' ' + order.orderOfferNumber, 10, 10);
              if (order.orderOfferNumber) {
                commonTableBody.push([
                  translate('resources.orders.fields.orderOfferNumber'),
                  order.orderOfferNumber
                ])
              }

              if (order.orderStatus) {
                commonTableBody.push([
                  translate('resources.orders.fields.orderStatus'),
                  translate(order.orderStatus)
                ])
              }

              if (order.orderAddress) {
                commonTableBody.push([
                  translate('resources.orders.fields.orderAddress'),
                  order.orderAddress
                ])
              }

              if (order.orderComment) {
                commonTableBody.push([
                  translate('resources.orders.fields.orderComment'),
                  { content: order.orderComment, styles: { cellWidth: 100 } }
                ])
              }

              // commonTableBody.push([
              //   { colSpan: 2, content: translate('Client'), styles: { halign: 'center' } }
              // ])

              if (order.orderClientName) {
                commonTableBody.push([
                  translate('resources.orders.fields.orderClientName'),
                  order.orderClientName
                ])
              }

              if (order.orderClientEmail) {
                commonTableBody.push([
                  translate('resources.orders.fields.orderClientEmail'),
                  order.orderClientEmail
                ])
              }

              if (order.orderClientMobilePhone) {
                commonTableBody.push([
                  translate('resources.orders.fields.orderClientMobilePhone'),
                  order.orderClientMobilePhone
                ])
              }

              commonTableBody.push([
                { colSpan: 2, content: '' }
              ])
              // doc.text(translate('resources.orders.fields.orderOfferNumber') + ' ' + order.orderOfferNumber, 10, 10);
            } else {
              doc.text(index + '. ' + translate(item.itemType) + ' - ' + item.itemRoomName, 10, 10);
            }

            if (item.itemFrameManufacturer) {
              commonTableBody.push([
                translate("resources.items.fields.itemFrameManufacturer"),
                manufacturersById[item.itemFrameManufacturer]
              ])
            }
            if (item.itemFrame) {
              commonTableBody.push([
                translate("resources.items.fields.itemFrame"),
                framesById[item.itemFrame]
              ])
            }

            if (item.itemWeldJoint) {
              commonTableBody.push([
                translate("resources.items.fields.itemWeldJoint"),
                item.itemWeldJoint
              ])
            }

            if (aanslag.length !== 0) {
              commonTableBody.push([
                'Aanslag',
                aanslag.join(" | ")
              ])
            }

            if (item.itemOpening) {
              commonTableBody.push([
                translate("resources.items.fields.itemOpening"),
                translate(item.itemOpening)
              ])
            }

            if (item.itemView) {
              commonTableBody.push([
                translate("resources.items.fields.itemView"),
                translate(item.itemView)
              ])
            }

            if (item.itemLocking) {
              commonTableBody.push([
                translate("resources.items.fields.itemLocking"),
                lockingsById[item.itemLocking]
              ])
            }

            if (item.itemGlass) {
              commonTableBody.push([
                translate("resources.items.fields.itemGlass"),
                glassesById[item.itemGlass]
              ])
            }
            if
              (item.itemOtherGlass) {
              commonTableBody.push([
                translate("resources.items.fields.itemOtherGlass"),
                item.itemOtherGlass
              ])
            }

            if (item.itemSafetyGlass) {
              commonTableBody.push([
                translate("resources.items.fields.itemSafetyGlass"),
                translate(item.itemSafetyGlass)
              ])
            }

            if (item.itemFrameColorInside && item.itemFrameColorInsideType) {
              commonTableBody.push([
                translate("resources.items.fields.itemFrameColorInside"),
                item.itemFrameColorInside + ' - ' + translate(item.itemFrameColorInsideType)
              ])
            }

            if (item.itemFrameColorOutside && item.itemFrameColorOutsideType) {
              commonTableBody.push([
                translate("resources.items.fields.itemFrameColorOutside"),
                item.itemFrameColorInside + ' - ' + translate(item.itemFrameColorInsideType)
              ])
            }

            if (item.itemSashColorInside && item.itemSashColorInsideType) {
              commonTableBody.push([
                translate("resources.items.fields.itemSashColorInside"),
                item.itemSashColorInside + ' - ' + translate(item.itemSashColorInsideType)
              ])
            }

            if (item.itemSashColorOutside && item.itemSashColorOutsideType) {
              commonTableBody.push([
                translate("resources.items.fields.itemSashColorOutside"),
                item.itemSashColorInside + ' - ' + translate(item.itemFrameColorInsideType)
              ])
            }

            // if (item.itemGridExists) {
            //   commonTableBody.push([
            // translate("resources.items.fields.itemGridExists"), item.itemGridExists ])
            // }

            if (item.itemGrid) {
              commonTableBody.push([
                translate("resources.items.fields.itemGrid"),
                translate(item.itemGrid)
              ])
            }

            if (item.itemFinishing) {
              commonTableBody.push([
                translate("resources.items.fields.itemFinishing"),
                item.itemFinishing ? "+" : "-"
              ])
            }

            if (item.itemLocation) {
              commonTableBody.push([
                translate("resources.items.fields.itemLocation"),
                item.itemLocation
              ])
            }

            if (item.itemComment) {
              commonTableBody.push([
                translate("resources.items.fields.itemComment"),
                // item.itemComment
                { content: item.itemComment, styles: { cellWidth: 100 } }
              ])
            }

            if ([
              'Uninstallation',
            ].includes(item.itemRollerShuttersInstallationType)) {
              commonTableBody.push([
                translate("resources.items.fields.itemRollerShuttersInstallationType"),
                translate(item.itemRollerShuttersInstallationType)
              ])
            }

            let startY = 20;
            if (scheme) {
              const height = 200 / scheme.pictureWidth * scheme.pictureHeight;
              startY += height
              const image = await resizeImage(scheme.pictureUrl, 0.5)
              doc.addImage(image, 'PNG', 0, 20, 200, height)
            }

            autoTable(doc, {
              startY,
              body: commonTableBody,
            })

            if (index === 0) {
              const [{ data: orderMeasurementPhotos }, { data: orderInstallationPhotos }] = await Promise.all([
                dataProvider.getList('pictures', {
                  filter: { pictureOrderId: order.orderId, pictureType: 'MEASUREMENT_PHOTO' },
                  sort: { field: 'pictureType', order: 'ASC' },
                  pagination: { page: 1, perPage: 100 },
                }),
                dataProvider.getList('pictures', {
                  filter: { pictureOrderId: order.orderId, pictureType: 'INSTALLATION_PHOTO' },
                  sort: { field: 'pictureType', order: 'ASC' },
                  pagination: { page: 1, perPage: 100 },
                })
              ]);

              if (orderMeasurementPhotos) {

                for (let j = 0; j < orderMeasurementPhotos.length; j++) {
                  doc.addPage('a4', 'portrait')
                  doc.text(translate('Order') + ' - ' + translate('MEASUREMENT_PHOTO'), 10, 10);
                  const measurementPhoto = orderMeasurementPhotos[j];
                  const height = 210 / measurementPhoto.pictureWidth * measurementPhoto.pictureHeight;
                  startY += height
                  const image = await resizeImage(measurementPhoto.pictureUrl, 0.3)
                  doc.addImage(image, 'JPEG', 0, 20, 210, height)
                }
              }

              if (orderInstallationPhotos) {

                for (let j = 0; j < orderInstallationPhotos.length; j++) {
                  doc.addPage('a4', 'portrait')
                  doc.text(translate('Order') + ' - ' + translate('INSTALLATION_PHOTO'), 10, 10);
                  const installationPhoto = orderInstallationPhotos[j];
                  const height = 210 / installationPhoto.pictureWidth * installationPhoto.pictureHeight;
                  startY += height
                  const image = await resizeImage(installationPhoto.pictureUrl, 0.3)
                  doc.addImage(image, 'JPEG', 0, 20, 210, height)
                }
              }
            }


            if (item.itemSill) {
              doc.addPage('a4', 'portrait')
              doc.text(index + '. ' + translate(item.itemType) + ' - ' + translate('SILL_SCHEME') + ' - ' + item.itemRoomName, 10, 10);

              startY = 20

              const { data: [sillScheme] } = await dataProvider.getList('pictures', {
                filter: { pictureItemId: item.itemId, pictureType: 'SILL_SCHEME' },
                sort: { field: 'pictureType', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
              });

              if (sillScheme) {
                const height = 200 / sillScheme.pictureWidth * sillScheme.pictureHeight;
                startY += height
                const image = await resizeImage(sillScheme.pictureUrl, 0.5)
                doc.addImage(image, 'PNG', 0, 20, 200, height)
              }

              autoTable(doc, {
                startY,
                body: [[{ content: translate('resources.items.fields.itemSill') }, { content: sillsById[item.itemSill] }]],
              })
            }

            if (item.itemStool) {
              doc.addPage('a4', 'portrait')
              doc.text(index + '. ' + translate(item.itemType) + ' - ' + translate('STOOL_SCHEME') + ' - ' + item.itemRoomName, 10, 10);

              startY = 20

              const { data: [stoolScheme] } = await dataProvider.getList('pictures', {
                filter: { pictureItemId: item.itemId, pictureType: 'STOOL_SCHEME' },
                sort: { field: 'pictureType', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
              });

              if (stoolScheme) {
                const height = 200 / stoolScheme.pictureWidth * stoolScheme.pictureHeight;
                startY += height
                const image = await resizeImage(stoolScheme.pictureUrl, 0.5)
                doc.addImage(image, 'PNG', 0, 20, 200, height)
              }

              autoTable(doc, {
                startY,
                body: [[{ content: translate('resources.items.fields.itemStool') }, { content: stoolsById[item.itemStool] }]],
              })
            }

            if ([
              'New installation',
              'Un- and Reinstallation'
            ].includes(item.itemRollerShuttersInstallationType)) {
              doc.addPage('a4', 'portrait')
              doc.text(index + '. ' + translate(item.itemType) + ' - ' + translate('ROLLERSHUTTERS_SCHEME') + ' - ' + item.itemRoomName, 10, 10);

              startY = 20

              const { data: [rollerShuttersScheme] } = await dataProvider.getList('pictures', {
                filter: { pictureItemId: item.itemId, pictureType: 'ROLLERSHUTTERS_SCHEME' },
                sort: { field: 'pictureType', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
              });

              if (rollerShuttersScheme) {
                const height = 200 / rollerShuttersScheme.pictureWidth * rollerShuttersScheme.pictureHeight;
                startY += height
                const image = await resizeImage(rollerShuttersScheme.pictureUrl, 0.5)
                doc.addImage(image, 'PNG', 0, 20, 200, height)

              }

              const rollerShuttersTable = []

              if (item.itemRollerShuttersInstallationType) {
                rollerShuttersTable.push([
                  translate("resources.items.fields.itemRollerShuttersInstallationType"),
                  translate(item.itemRollerShuttersInstallationType)
                ])
              }

              if (item.itemRollerShuttersControlType) {
                rollerShuttersTable.push([
                  translate("resources.items.fields.itemRollerShuttersControlType"),
                  translate(item.itemRollerShuttersControlType)
                ])
              }

              if (item.itemRollerShuttersControlPosition) {
                rollerShuttersTable.push([
                  translate("resources.items.fields.itemRollerShuttersControlPosition"),
                  translate(item.itemRollerShuttersControlPosition)
                ])
              }

              if (item.itemRollerShuttersGuideRails) {
                rollerShuttersTable.push([
                  translate("resources.items.fields.itemRollerShuttersGuideRails"),
                  item.itemRollerShuttersGuideRails
                ])
              }

              if (item.itemRollerShuttersGuideRailsColor) {
                rollerShuttersTable.push([
                  translate("resources.items.fields.itemRollerShuttersGuideRailsColor"),
                  item.itemRollerShuttersGuideRailsColor
                ])
              }

              if (item.itemRollerShuttersPanelBoxColor) {
                rollerShuttersTable.push([
                  translate("resources.items.fields.itemRollerShuttersPanelBoxColor"),
                  item.itemRollerShuttersPanelBoxColor
                ])
              }

              if (item.itemRollerShuttersSlatsColor) {
                rollerShuttersTable.push([
                  translate("resources.items.fields.itemRollerShuttersSlatsColor"),
                  item.itemRollerShuttersSlatsColor
                ])
              }

              if (item.itemRollerShuttersBaseSlatColor) {
                rollerShuttersTable.push([
                  translate("resources.items.fields.itemRollerShuttersBaseSlatColor"),
                  item.itemRollerShuttersBaseSlatColor
                ])
              }

              if (item.itemRollerShuttersComments) {
                rollerShuttersTable.push([
                  translate("resources.items.fields.itemRollerShuttersComments"),
                  item.itemRollerShuttersComments
                ])
              }

              autoTable(doc, {
                startY,
                body: rollerShuttersTable,
              })

            }

            const [{ data: itemMeasurementPhotos }, { data: itemInstallationPhotos }] = await Promise.all([
              dataProvider.getList('pictures', {
                filter: { pictureItemId: item.itemId, pictureType: 'MEASUREMENT_PHOTO' },
                sort: { field: 'pictureType', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
              }),
              dataProvider.getList('pictures', {
                filter: { pictureItemId: item.itemId, pictureType: 'INSTALLATION_PHOTO' },
                sort: { field: 'pictureType', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
              })
            ]);

            if (itemMeasurementPhotos) {

              for (let j = 0; j < itemMeasurementPhotos.length; j++) {
                doc.addPage('a4', 'portrait')
                doc.text(index + '. ' + translate(item.itemType) + ' - ' + translate('MEASUREMENT_PHOTO') + ' - ' + item.itemRoomName, 10, 10);
                const measurementPhoto = itemMeasurementPhotos[j];
                const height = 210 / measurementPhoto.pictureWidth * measurementPhoto.pictureHeight;
                startY += height
                const image = await resizeImage(measurementPhoto.pictureUrl, 0.3)
                doc.addImage(image, 'JPEG', 0, 20, 210, height)
              }
            }

            if (itemInstallationPhotos) {

              for (let j = 0; j < itemInstallationPhotos.length; j++) {
                doc.addPage('a4', 'portrait')
                doc.text(index + '. ' + translate(item.itemType) + ' - ' + translate('INSTALLATION_PHOTO') + ' - ' + item.itemRoomName, 10, 10);
                const installationPhoto = itemInstallationPhotos[j];
                const height = 210 / installationPhoto.pictureWidth * installationPhoto.pictureHeight;
                startY += height
                const image = await resizeImage(installationPhoto.pictureUrl, 0.3)
                doc.addImage(image, 'JPEG', 0, 20, 210, height)
              }
            }


            if (items[index + 1]) {
              doc.addPage('a4', 'portrait')
            }
          }
          notify('Export success finished', 'info')
          doc.save(order.id + ".pdf");

        } catch (error) {
          notify('Export failed', 'error')
          console.log(error)
        }
        setDisabled(false)
      })();

    },
    [dataProvider, notify, order.id, order.orderAddress, order.orderClientEmail, order.orderClientMobilePhone, order.orderClientName, order.orderComment, order.orderId, order.orderOfferNumber, order.orderStatus, translate],
  )


  return (
    <TopToolbar>
      <Button disabled={disabled} onClick={generateDocuments}>
        EXPORT PDF
      </Button>
    </TopToolbar>
  )
}

export const OrderEdit = (props: any) => {
  const classes = useEditStyles();
  return (
    <Edit
      actions={<Actions />}
      title={<OrderTitle />}
      classes={classes}
      {...props}
      component="div"
    >
      <OrderForm />
    </Edit>
  );
}

export default OrderEdit;