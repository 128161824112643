
import React, { memo } from 'react'
import { useQueryWithStore, Loading, Error, useTranslate } from 'react-admin'
import Box from '@material-ui/core/Box'
import {
  // IconButton,
  withStyles
} from '@material-ui/core';
import { SRLWrapper } from "simple-react-lightbox";
import Typography from '@material-ui/core/Typography'
// import EditIcon from '@material-ui/icons/Edit';
// import CanvasDialog from '~/components/CanvasDialog'

const StyledBox = withStyles((theme) => ({
  root: {
    "&>div": {
      flexDirection: "row",
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      display: 'flex',
      overflowX: 'auto',
      padding: 8
    },
    position: "relative",
    height: 245,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingBottom: "8px",



  },
}))(Box);

const options = {
  settings: {
    autoplaySpeed: 3000,
    boxShadow: 'none',
    disableKeyboardControls: false,
    disablePanzoom: false,
    disableWheelControls: false,
    hideControlsAfter: false,
    lightboxTransitionSpeed: 0.3,
    lightboxTransitionTimingFunction: 'linear',
    overlayColor: 'rgba(30, 30, 30, 0.9)',
    slideAnimationType: 'fade',
    slideSpringValues: [300, 50],
    slideTransitionSpeed: 0.6,
    slideTransitionTimingFunction: 'linear',
    usingPreact: false
  },
  caption: {
    captionAlignment: 'start',
    captionColor: '#FFFFFF',
    captionContainerPadding: '20px 0 30px 0',
    captionFontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    captionFontSize: '1rem',
    captionFontStyle: 'inherit',
    captionFontWeight: '400',
    captionTextTransform: 'inherit',
    showCaption: true
  },
  buttons: {
    backgroundColor: 'rgba(30,30,36,0.8)',
    iconColor: 'rgba(255, 255, 255, 0.8)',
    iconPadding: '10px',
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: true,
    showFullscreenButton: true,
    showNextButton: false,
    showPrevButton: false,
    showThumbnailsButton: false,
    size: '40px'
  },
  thumbnails: {
    showThumbnails: false,
    // thumbnailsAlignment: 'center',
    // thumbnailsContainerBackgroundColor: 'transparent',
    // thumbnailsContainerPadding: '0',
    // thumbnailsGap: '0 1px',
    // thumbnailsIconColor: '#ffffff',
    // thumbnailsOpacity: 0.4,
    // thumbnailsPosition: 'bottom',
    // thumbnailsSize: ['100px', '80px']
  },
  progressBar: {},
  translations: {}, // PRO ONLY
  icons: {} // PRO ONLY
}


const Pictures = memo(({ filter }: any) => {
  const { data, loading, error, loaded } = useQueryWithStore({
    type: 'getList',
    resource: 'pictures',
    payload: {
      // filter: { pictureOrderId: order.orderId },
      filter,
      sort: { field: 'pictureType', order: 'ASC' },
      pagination: { page: 1, perPage: 100 },
    },
  });
  const translate = useTranslate()

  // const [pictureId, setPictureId] = React.useState(null)

  if (!loaded) { return <Loading />; }
  if (error) { return <Error />; }
  if (data.length === 0) return null;

  // const handleOpenCanvas = useCallback(
  //   (id) => {
  //     // setPictureId(id)
  //   },
  //   [setPictureId],
  // )

  // console.log(data)
  return (<StyledBox>
    <SRLWrapper options={options}>
      {data.map(({ pictureUrl, pictureWidth, pictureHeight, pictureType, id }, index) => {
        const width = Math.ceil(200 / pictureHeight * pictureWidth);
        return (
          <div style={{ width, height: 220, paddingRight: 8, position: 'relative' }}>
            {/* <IconButton style={{ position: 'absolute', top: 10, right: 10, backgroundColor: 'grey' }} edge="start" color="inherit" onClick={() => {
              setPictureId(id)
            }} aria-label="close">
              <EditIcon />
            </IconButton> */}
            <a href={pictureUrl} >
              <img style={{ height: 200, width }} alt={translate(pictureType)} src={pictureUrl} />
            </a>
            <Typography style={{ height: 20 }}>{translate(pictureType)}</Typography>
          </div>
        )
      })}
    </SRLWrapper>
    {/* <CanvasDialog pictureId={pictureId} /> */}
  </StyledBox>)
})

export default Pictures;