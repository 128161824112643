import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  ShowButton,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanField,
  BooleanInput,
} from 'react-admin';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/core';


export const LockingIcon = LockIcon;

const useStyles = makeStyles({
  rowCell: {
    whiteSpace: 'nowrap'
  },
});

export const LockingsList = (props) => {
  const classes = useStyles()

  return (<List {...props} >
    <Datagrid classes={classes}>
      <EditButton basePath="/lockings" />
      <ShowButton />
      <TextField source="name" />
      <BooleanField source="visible" />
    </Datagrid>
  </List>)
};

const LockingTitle = ({ record }) => {
  return <span>Locking {record ? `"${record.employeeName}"` : ''}</span>;
};

export const LockingShow = (props) => {
  return (
    <Show title={<LockingTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <BooleanField source="visible" />
      </SimpleShowLayout>
    </Show>
  )
}

export const LockingEdit = (props) => {
  return (
    <Edit title={<LockingTitle />} {...props}>
      <SimpleForm aria-rowcount={4}>
        <TextInput source="name" />
        <BooleanInput source="blocked" />
      </SimpleForm>
    </Edit>
  )
};

export const LockingCreate = (props) => {
  return (
    <Create title="Create a Locking" {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <BooleanInput source="visible" defaultChecked />
      </SimpleForm>
    </Create>
  )
};

export default {
  icon: LockIcon,
  list: LockingsList
}