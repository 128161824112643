import polyglotI18nProvider from 'ra-i18n-polyglot';
// import { resolveBrowserLocale } from 'react-admin'
import nlRa from 'ra-language-dutch'
import enRa from 'ra-language-english'
import ruRa from 'ra-language-russian'

import en from '~/i18n/en.json';
import ru from '~/i18n/ru.json';
import nl from '~/i18n/nl.json';
import resources from '~/i18n/resources';
import getLocaleFromStorage from '~/helpers/getLocaleFromStorage';

const messages = {
  'nl': {
    ...nlRa,
    ...nl.translation,
    ...{ resources: resources.nl }
  },
  'ru': {
    ...ruRa,
    ...ru.translation,
    ...{ resources: resources.ru }
  },
  'en': {
    ...enRa,
    ...en.translation,
    ...{ resources: resources.en }
  }
}


const i18nProvider = polyglotI18nProvider(locale =>
  messages[locale],
  getLocaleFromStorage()
);

export default i18nProvider