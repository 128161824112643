import * as React from "react";
import {
  Show,
  Loading,
  ShowButton,
  Filter,
  SelectInput,
  List,
  SelectArrayInput,
  TextField,
  SimpleShowLayout,
  BooleanField,
  withTranslate,
  BooleanInput,
  ReferenceField,
  Labeled,
  useShowController
} from 'react-admin';
import CustomizableDatagrid from 'ra-customizable-datagrid';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
// import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import TranslatedTextField from '~/components/TranslatedTextField'
// import createId from './helpers/createId'
import Pictures from '~/components/Pictures'


export const ItemIcon = InsertDriveFileIcon;

const ItemsFilter = withTranslate((props) => (
  <Filter {...props}>
    {/* <TextInput label="Search" source="q" alwaysOn /> */}
    {/* <TextInput label="Status" source="title" defaultValue="Hello, World!" /> */}
    <SelectArrayInput source="itemType" choices={[
      { id: "DOOR", name: props.translate("Door") },
      { id: "WINDOW", name: props.translate("Window") },
      { id: "COMMON", name: props.translate("Door/Window") },
    ]} />
    <SelectInput source="itemSize" choices={[
      { id: 'Accurate', name: props.translate('Accurate') },
      { id: 'For offer', name: props.translate('For offer') },
    ]} />
    <SelectInput source="itemFrameColorInsideType" choices={[
      { id: 'Mass', name: props.translate('Mass') },
      { id: 'Membrane', name: props.translate('Membrane') },
    ]} />
    <SelectInput source="itemFrameColorOutsideType" choices={[
      { id: 'Mass', name: props.translate('Mass') },
      { id: 'Membrane', name: props.translate('Membrane') },
    ]} />
    <SelectInput source="itemSashColorInsideType" choices={[
      { id: 'Mass', name: props.translate('Mass') },
      { id: 'Membrane', name: props.translate('Membrane') },
    ]} />
    <SelectInput source="itemSashColorOutsideType" choices={[
      { id: 'Mass', name: props.translate('Mass') },
      { id: 'Membrane', name: props.translate('Membrane') },
    ]} />
    <BooleanInput source="itemSillExists" />
    <BooleanInput source="itemStoolExists" />
    <SelectInput source="itemRollerShuttersInstallationType" choices={[
      { id: 'New installation', name: props.translate('New installation') },
      { id: 'Uninstallation', name: props.translate('Uninstallation') },
      { id: 'Un- and Reinstallation', name: props.translate('Un- and Reinstallation') },
    ]} />
  </Filter>
));

export const ItemsList = (props) => {
  return (
    <List {...props} filters={<ItemsFilter />}>
      <CustomizableDatagrid defaultColumns={[
        'itemType',
        'itemSillExists',
        'itemStoolExists',
        'itemRollerShuttersInstallationType',
        'itemFrame',
        'itemOpening',
        'itemGlass',
        'itemLocking',
      ]}>
        <ReferenceField link="show" label="Order" resource="orders" source="itemOrderId" reference="orders">
          <TextField source="orderOfferNumber" />
        </ReferenceField>
        {/* <ReferenceField label="Order" resource="items" source="itemOrderId" reference="orders" basePath="/items" >
          <TextField source="orderId" defaultValue="Order" />
        </ReferenceField> */}
        <TranslatedTextField source="itemType" />
        <TextField source="itemRoomName" />
        <ReferenceField link="show" label="Frame" resource="items" source="itemFrame" reference="frames" >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="itemFrameColorInside" />
        <TextField source="itemFrameColorInsideType" />
        <TextField source="itemFrameColorOutside" />
        <TextField source="itemFrameColorOutsideType" />
        <TextField source="itemSashColorInside" />
        <TextField source="itemSashColorInsideType" />
        <TextField source="itemSashColorOutside" />
        <TextField source="itemSashColorOutsideType" />
        <TextField source="itemOpening" />
        <ReferenceField link="show" label="resources.items.fields.itemGlass" resource="items" source="itemGlass" reference="glasses" >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField link="show" label="resources.items.fields.itemLocking" resource="items" source="itemLocking" reference="lockings" >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="itemAanslagBottomExists" />
        <BooleanField source="itemAanslagTopExists" />
        <BooleanField source="itemAanslagRightExists" />
        <BooleanField source="itemAanslagLeftExists" />
        <ReferenceField link="show" label="resources.items.fields.itemSill" resource="items" source="itemSill" reference="sills" >
          <TextField label="resources.items.fields.itemSill" source="name" />
        </ReferenceField>
        <ReferenceField link="show" label="resources.items.fields.itemStool" resource="items" source="itemStool" reference="stools" >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="itemGrid" />
        <TextField source="itemSafetyGlass" />
        <TranslatedTextField source="itemRollerShuttersInstallationType" />
        <TextField source="itemRollerShuttersControlType" />
        <TextField source="itemRollerShuttersControlPosition" />
        <TextField source="itemRollerShuttersGuideRails" />
        <TextField source="itemRollerShuttersGuideRailsColor" />
        <TextField source="itemRollerShuttersPanelBoxColor" />
        <TextField source="itemRollerShuttersSlatsColor" />
        <TextField source="itemRollerShuttersBaseSlatColor" />
        <TextField source="itemRollerShuttersComments" />
        <TextField source="itemSize" />
        <TextField source="itemLocation" />
        <TextField source="itemComment" />
        <TextField source="itemCreatorName" />
        {/* <DateField source="createdAt" />
        <DateField source="updatedAt" /> */}
        {/* <EditButton basePath="/items" /> */}
        <ShowButton />
      </CustomizableDatagrid>
    </List>
  )
};


export const ItemShow = (props) => {
  // const item = {}

  const {
    // basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    // loaded, // boolean that is false until the record is available
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    // resource, // the resource name, deduced from the location. e.g. 'posts'
    // version, // integer used by the refresh feature
  } = useShowController(props);


  if (loading) return <Loading />;
  // if (error) return <Error />;
  // if (!data) return null;
  // console.log(props)
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom component="div">
          {defaultTitle}
        </Typography>
        {/* </Labeled> */}
        <Labeled label="resources.items.fields.itemSafetyGlass">
          <TranslatedTextField source="itemSafetyGlass" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemGlass">
          <ReferenceField
            link={false}
            label="Glass"
            resource="items"
            source="itemGlass"
            reference="glasses"
            record={record}
            basePath="/items"
          >
            <TextField source="name" record={record} />
          </ReferenceField>
        </Labeled>
        <Labeled label="resources.items.fields.itemOtherGlass">
          <TextField source="itemOtherGlass" record={record} label="resources.items.fields.itemOtherGlass" />
        </Labeled>
        <Labeled label="resources.items.fields.itemAanslagBottomExists">
          <BooleanField source="itemAanslagBottomExists" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemAanslagTopExists">
          <BooleanField source="itemAanslagTopExists" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemAanslagRightExists">
          <BooleanField source="itemAanslagRightExists" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemAanslagLeftExists">
          <BooleanField source="itemAanslagLeftExists" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemFrameColorInside">
          <TextField source="itemFrameColorInside" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemFrameColorOutside">
          <TextField source="itemFrameColorOutside" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemSashColorInside">
          <TextField source="itemSashColorInside" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemSashColorOutside">
          <TextField source="itemSashColorOutside" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemFrameColorInsideType">
          <TranslatedTextField source="itemFrameColorInsideType" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemFrameColorOutsideType">
          <TranslatedTextField source="itemFrameColorOutsideType" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemSashColorInsideType">
          <TranslatedTextField source="itemSashColorInsideType" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemSashColorOutsideType">
          <TranslatedTextField source="itemSashColorOutsideType" record={record} />
        </Labeled>
        <Labeled label="resources.items.fields.itemSize">
          <TranslatedTextField record={record} source="itemSize" />
        </Labeled>
        <Labeled label="resources.items.fields.itemSizeAanslag">
          <TranslatedTextField record={record} source="itemSizeAanslag" />
        </Labeled>
        <Labeled label="resources.items.fields.itemFrame">
          <ReferenceField
            link={false}
            label="Frame"
            resource="items"
            source="itemFrame"
            reference="frames"
            record={record}
            basePath="/items"
          >
            <TextField source="name" record={record} label="resources.items.fields.itemFrame" />
          </ReferenceField>
        </Labeled>
        <Labeled label="resources.items.fields.itemFrameManufacturer">
          <ReferenceField
            link={false}
            label="Frame Manufacturer"
            resource="items"
            source="itemFrameManufacturer"
            reference="manufacturers"
            record={record}
            basePath="/items"
          >
            <TextField source="name" record={record} label="resources.items.fields.itemFrameManufacturer" />
          </ReferenceField>
        </Labeled>
        <Labeled label="resources.items.fields.itemStool">
          <ReferenceField
            link={false}
            label="Stool"
            resource="items"
            source="itemStool"
            reference="stools"
            record={record}
            basePath="/items"
          >
            <TextField source="name" record={record} label="resources.items.fields.itemStool" />
          </ReferenceField>
        </Labeled>
        <Labeled label="resources.items.fields.itemSill">
          <ReferenceField
            link={false}
            label="Sill"
            resource="items"
            source="itemSill"
            reference="sills"
            record={record}
            basePath="/items"
          >
            <TextField source="name" record={record} label="resources.items.fields.itemSill" />
          </ReferenceField>
        </Labeled>

        <Labeled label="resources.items.fields.itemOpening">
          <TextField record={record} source="itemOpening" />
        </Labeled>
        <Labeled label="resources.items.fields.itemView">
          <TextField record={record} source="itemView" />
        </Labeled>
        <Labeled label="resources.items.fields.itemRollerShuttersInstallationType">
          <TranslatedTextField record={record} source="itemRollerShuttersInstallationType" />
        </Labeled>
        <Labeled label="resources.items.fields.itemRollerShuttersControlType">
          <TranslatedTextField record={record} source="itemRollerShuttersControlType" />
        </Labeled>
        <Labeled label="resources.items.fields.itemRollerShuttersControlPosition" >
          <TranslatedTextField record={record} source="itemRollerShuttersControlPosition" />
        </Labeled>
        <Labeled label="resources.items.fields.itemRollerShuttersGuideRails" >
          <TextField record={record} source="itemRollerShuttersGuideRails" />
        </Labeled>
        <Labeled label="resources.items.fields.itemRollerShuttersGuideRailsColor">
          <TextField record={record} source="itemRollerShuttersGuideRailsColor" />
        </Labeled>
        <Labeled label="resources.items.fields.itemRollerShuttersPanelBoxColor">
          <TextField record={record} source="itemRollerShuttersPanelBoxColor" />
        </Labeled>
        <Labeled label="resources.items.fields.itemRollerShuttersSlatsColor">
          <TextField record={record} source="itemRollerShuttersSlatsColor" />
        </Labeled>
        <Labeled label="resources.items.fields.itemRollerShuttersBaseSlatColor">
          <TextField record={record} source="itemRollerShuttersBaseSlatColor" />
        </Labeled>
        <Labeled label="resources.items.fields.itemRollerShuttersComments">
          <TextField component="pre" record={record} source="itemRollerShuttersComments" />
        </Labeled>
        <Labeled label="resources.items.fields.itemComment">
          <TextField component="pre" record={record} source="itemComment" />
        </Labeled>
        <Pictures filter={{ pictureItemId: record.itemId }} />
      </SimpleShowLayout >
    </Show>
  )
}

// const ItemTitle = ({record}) => {
//   return <span>Item {record ? `"${record.itemOfferNumber}"` : ''}</span>;
// };

// export const ItemEdit = (props) => {

//   return (
//     <Edit title={<ItemTitle />} {...props}>
//       <SimpleForm aria-rowcount={4}>
//         <TextInput disabled source="itemId" />
//         <SelectInput source="itemType" choices={[
//           { id: "DOOR", name: "Door" },
//           { id: "WINDOW", name: "Window" },
//           { id: "COMMON", name: "Door/Window" },
//         ]} />
//         <TextField source="RoomName" />
//         <TextField source="Aanslag" />
//         <TextField source="Frame" />
//         <TextField source="FrameColorInside" />
//         <TextField source="FrameColorInsideType" />
//         <TextField source="FrameColorOutside" />
//         <TextField source="FrameColorOutsideType" />
//         <TextField source="SashColorInside" />
//         <TextField source="SashColorInsideType" />
//         <TextField source="SashColorOutside" />
//         <TextField source="SashColorOutsideType" />
//         <TextField source="Opening" />
//         <TextField source="Glass" />
//         <TextField source="Locking" />
//         <BooleanField source="Aanslag Bottom" />
//         <BooleanField source="Aanslag Top" />
//         <BooleanField source="Aanslag Right" />
//         <BooleanField source="Aanslag Left" />
//         <BooleanField source="Sill" />
//         <TextField source="Sill Type" />
//         <BooleanField source="Stool" />
//         <TextField source="Stool Type" />
//         <BooleanField source="SafetyGlassExists" />
//         <TextField source="SafetyGlassType" />
//         <BooleanField source="RollerShutters" />
//         <TextField source="RollerShutters Control Type" />
//         <TextField source="RollerShutters Control Position" />
//         <TextField source="RollerShutters GuideRails" />
//         <TextField source="RollerShutters GuideRails Color" />
//         <TextField source="RollerShutters PanelBox Color" />
//         <TextField source="RollerShutters Slats Color" />
//         <TextField source="RollerShutters Base Slat Color" />
//         <TextField source="RollerShutters Comments" />
//         <TextField source="Size" />
//         <TextField source="Location" />
//         <TextField source="Comment" />
//         <TextField source="CreatorName" />
//       </SimpleForm>
//     </Edit>
//   )
// };

// export const ItemCreate = (props) => {
//   const [itemId] = React.useState(createId())
//   return (
//     <Create title="Create a Item" {...props}>
//       <SimpleForm>
//         <TextInput disabled source="itemId" defaultValue={itemId} />
//         <SelectInput source="itemType" choices={[
//           { id: "DOOR", name: "Door" },
//           { id: "WINDOW", name: "Window" },
//           { id: "COMMON", name: "Door/Window" },
//         ]} />
//         <TextInput source="itemOfferNumber" />
//         <TextInput required source="itemAddress" />
//         <TextInput source="itemClientEMail" />
//         <TextInput required source="itemClientName" />
//         <TextInput required source="itemClientMobilePhone" />
//         <TextInput source="itemComment" multiline />
//       </SimpleForm>
//     </Create>
//   )
// };


export default {
  list: ItemsList,
  // edit: ItemEdit,
  icon: ItemIcon,
};