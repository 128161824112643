export const statusesChoises = [
  { id: "OPEN", name: "OPEN" },
  { id: "WAITING_FOR_MEASUREMENT", name: "WAITING_FOR_MEASUREMENT" },
  { id: "MEASUREMENT", name: "MEASUREMENT" },
  { id: "MEASURED", name: "MEASURED" },
  { id: "WAITING_FOR_ESTIMATION", name: "WAITING_FOR_ESTIMATION" },
  { id: "WAITING_FOR_APPROVE", name: "WAITING_FOR_APPROVE" },
  { id: "DECLINED", name: "DECLINED" },
  { id: "WAITING_FOR_PAYMENT", name: "WAITING_FOR_PAYMENT" },
  { id: "WAITING_FOR_MANUFACTURING", name: "WAITING_FOR_MANUFACTURING" },
  { id: "WAITING_FOR_INSTALLATION", name: "WAITING_FOR_INSTALLATION" },
  { id: "INSTALLATION", name: "INSTALLATION" },
  { id: "INSTALLED", name: "INSTALLED" },
  // { id: "DONE", name: "DONE" },
]