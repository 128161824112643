import * as React from "react";
import {
  SelectInput,
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { statusesChoises } from "~/constants";
import createId from '~/helpers/createId'

export const OrderCreate = (props) => {
  const [orderId] = React.useState(createId())
  return (
    <Create title="Create a Order" {...props}>
      <SimpleForm>
        <TextInput disabled source="orderId" defaultValue={orderId} />
        <SelectInput source="orderStatus" defaultValue="OPEN" choices={statusesChoises} />
        <TextInput source="orderOfferNumber" />
        <TextInput required source="orderAddress" />
        <TextInput source="orderClientEMail" />
        <TextInput required source="orderClientName" />
        <TextInput required source="orderClientMobilePhone" />
        <TextInput source="orderComment" multiline />
      </SimpleForm>
    </Create>
  )
};

export default OrderCreate;