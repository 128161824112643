// @ts-ignore
import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { withTranslate, FunctionField, FunctionFieldProps, useTranslate } from 'react-admin';
// import get from 'lodash/get';

// const connect = function (Component: React.FC): React.FC<Props> {
//   const ComponentWrapper = function (props: Props): JSX.Element {
//       return <Component {...props} />;
//   };

//   return ComponentWrapper;
// };

const TranslateField: FC<any> = (({ source, ...props }: FunctionFieldProps) => {
  const translate = useTranslate()
  return (
    <FunctionField {...props}
      render={(record) => record[source] ? translate(record[source]) : ''}
    />
  )
});
// TranslateField.propTypes = {
//   namespace: PropTypes.string
// }
// TranslateField.defaultProps = {
//   namespace: 'dbphrases',
// };

export default (TranslateField);