import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  ShowButton,
  DateField,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanField,
  BooleanInput,
  ReferenceField,
} from 'react-admin';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/core';


export const LogIcon = LockIcon;

const useStyles = makeStyles({
  rowCell: {
    whiteSpace: 'nowrap'
  },
});

export const LogsList = (props) => {
  const classes = useStyles()

  return (<List {...props} >
    <Datagrid classes={classes}>
      {/* <ShowButton /> */}
      <TextField label="resources.logs.fields.actionType"  source="actionType" />
      {/* <TextField source="documentId" /> */}
      {/* <ReferenceField label="Order" source="documentId" reference="orders">
        <TextField source="orderOfferNumber" />
      </ReferenceField> */}
      <TextField label="resources.logs.fields.documentType" source="documentType" />
      <TextField label="resources.logs.fields.documentField" source="documentField" />
      <DateField label="resources.logs.fields.actionDate" source="actionDate" />
      <TextField label="resources.logs.fields.prevValue" source="prevValue" />
      <TextField label="resources.logs.fields.nextValue" source="nextValue" />
      <ReferenceField label="resources.logs.fields.user" source="user" reference="users">
        <TextField source="employeeName" />
      </ReferenceField>
      {/* <ReferenceField
        label="User"
        // resource="logs"
        reference="users"
        source="user"
        record={{}}
        // basePath="/logs"
        >
        <TextField source="employeeName" />
      </ReferenceField> */}
      {/* <ReferenceField
        label="Order"
        resource="pictures"
        reference="orders"
        source="pictureOrderId"
        // record={data[id]}
        // basePath={basePath}
      >
        <TextField defaultValue="order" source="orderOfferNumber" />
      </ReferenceField> */}
    </Datagrid>
  </List>)
};

// const LogTitle = ({ record }) => {
//   return <span>Log {record ? `"${record.employeeName}"` : ''}</span>;
// };

// export const LogShow = (props) => {
//   return (
//     <Show title={<LogTitle />} {...props}>
//       <SimpleShowLayout>
//         <TextField source="actionType" />
//         {/* <TextField source="documentId" /> */}
//         <TextField source="documentType" />
//         <TextField source="documentField" />
//         <DateField source="actionDate" />
//         <TextField source="prevValue" />
//         <ReferenceField
//           resource="logs"
//           reference="users"
//           basePath="/logs"
//           source="user">
//           <TextField source="employeeName" />
//         </ReferenceField>
//         <TextField source="nextValue" />
//       </SimpleShowLayout>
//     </Show>
//   )
// }

// export const LogEdit = (props) => {
//   return (
//     <Edit title={<LogTitle />} {...props}>
//       <SimpleForm aria-rowcount={4}>
//         <TextInput source="name" />
//         <BooleanInput source="blocked" />
//       </SimpleForm>
//     </Edit>
//   )
// };

// export const LogCreate = (props) => {
//   return (
//     <Create title="Create a Log" {...props}>
//       <SimpleForm>
//         <TextInput source="name" />
//         <BooleanInput source="visible" defaultChecked />
//       </SimpleForm>
//     </Create>
//   )
// };

export default {
  icon: LockIcon,
  list: LogsList,
  name: 'logs'
}